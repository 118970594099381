import {
  Badge,
  FlagAvatar,
  Flex,
  Loader,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableProvider,
  TableRow
} from '@percent/lemonade'
import format from 'date-fns/format'
import { useTranslation } from 'react-i18next'
import { ColumnHeader, GrantProgramApplicationsTableProps } from './GrantProgramApplicationsTable.types'
import { Alpha3Code } from 'i18n-iso-countries'

export const columnsHeaders: ColumnHeader[] = [
  { header: 'table.header.country', isSortable: false },
  { header: 'table.header.organization', isSortable: true },
  { header: 'table.header.nominator', isSortable: true },
  { header: 'table.header.stage', isSortable: false },
  { header: 'table.header.submittedAt', isSortable: true }
]

export function GrantProgramApplicationsTable({
  data,
  isLoading,
  refresh,
  previousPage,
  nextPage,
  totalResults
}: GrantProgramApplicationsTableProps) {
  const { t } = useTranslation()

  const columnsPrograms = columnsHeaders.map((column, _index) => ({
    id: t(column.header),
    isSortable: column.isSortable
  }))

  const mockTotalResults = data?.length || 0

  const formatDate = (date: string) => {
    return format(new Date(date), 'dd MMM yyyy HH:mm')
  }

  if (isLoading) {
    return <Loader />
  }

  return (
    <Flex direction="column" w="100%" h="100%">
      <TableProvider
        columns={columnsPrograms}
        totalResults={mockTotalResults}
        isLoading={isLoading}
        illustration="no-results"
        emptyTableTitle={t('typography.noListTitle', { listType: 'grant program applications' })}
        emptyTableDescription={t('typography.noListDescription', { listType: 'grant program applications' })}
      >
        <Table>
          <TableBody>
            {data?.map(({ candidateId, organisation, nominatorEmail, currentState, submittedAt }, _index) => (
              <TableRow
                key={`grant-program-application-${candidateId}`}
                data-testid={`grant-program-application-${candidateId}`}
              >
                <TableCell>
                  <FlagAvatar code3={organisation.countryCode as Alpha3Code} width={'32px'} />
                </TableCell>
                <TableCell>{organisation.name}</TableCell>
                <TableCell>{nominatorEmail}</TableCell>
                <TableCell>
                  <Badge variant={'default'}>{currentState}</Badge>
                </TableCell>
                <TableCell>{formatDate(submittedAt)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Flex direction="column" p="10px">
          <TablePagination
            totalResultsText={t('table.results', { count: totalResults || undefined })}
            previousPage={previousPage}
            nextPage={nextPage}
          />
        </Flex>
      </TableProvider>
    </Flex>
  )
}
