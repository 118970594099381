import { Flex } from '@percent/lemonade'
import { GrantProgramsTable } from './grantProgramsTable/GrantProgramsTable'
import styles from './GrantProgramsContainer.module.scss'
import { mockGrantProgramsData } from './fixtures/mockGrantProgramsData'

export function GrantProgramsContainer() {
  // get data from GET v1/grants/programs

  const mockIsLoading = false
  const mockRefresh = () => {
    console.log('')
  }

  return (
    <Flex h="70vh" bg="white" className={styles.grantProgramsContainer} data-testid="grant-programs-container">
      <GrantProgramsTable data={mockGrantProgramsData} isLoading={mockIsLoading} refresh={mockRefresh} />
    </Flex>
  )
}
