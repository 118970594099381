import { Table, TableBody, TableProvider, TableRow, TableCell, Flex, Loader } from '@percent/lemonade'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import format from 'date-fns/format'
import { RoutePath } from '../../layout/Routes'
import { GrantProgramsTableProps } from './GrantProgramsTable.types'

export function GrantProgramsTable({ data, isLoading, refresh }: GrantProgramsTableProps) {
  const { t } = useTranslation()
  const history = useHistory()

  const columnsPrograms = [
    { id: t('table.header.grantPrograms'), isSortable: false },
    { id: t('table.header.startDate'), isSortable: false },
    { id: t('table.header.endDate'), isSortable: false }
  ]

  const mockTotalResults = data?.length || 0

  const rowCategoryClicked = (programId: string) => history.push(`${RoutePath.GRANT_PROGRAMS}/${programId}`)

  const formatDate = (date: string) => {
    return format(new Date(date), 'dd MMM yyyy')
  }

  if (isLoading) {
    return <Loader />
  }

  return (
    <Flex direction="column" w="100%" h="100%">
      <TableProvider
        columns={columnsPrograms}
        totalResults={mockTotalResults}
        isLoading={isLoading}
        illustration="no-results"
        emptyTableTitle={t('typography.noListTitle', { listType: 'grant programs' })}
        emptyTableDescription={t('typography.noListDescription', { listType: 'grant programs' })}
      >
        <Table>
          <TableBody>
            {data?.map(({ id, name, opens_at, closes_at }, _index) => (
              <TableRow
                key={`grant-programs-list-${id}`}
                onClick={() => rowCategoryClicked(id)}
                data-testid={`${name}-program`}
              >
                <TableCell>{name}</TableCell>
                <TableCell>{formatDate(opens_at)}</TableCell>
                <TableCell>{formatDate(closes_at)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableProvider>
    </Flex>
  )
}
