import { Flex } from '@percent/lemonade'
import styles from './GrantProgramApplicationsContainer.module.scss'
import { GrantProgramApplicationsTable } from './grantProgramApplicationsTable/GrantProgramApplicationsTable'
import { mockGrantProgramCandidates } from '../fixtures/mockGrantProgramApplications.Data'

export function GrantProgramApplicationsContainer() {
  // get data from GET v1/grants/programs/:programId/candidates

  const mockIsLoading = false
  const mockRefresh = () => {
    console.log('')
  }

  return (
    <Flex
      h="70vh"
      bg="white"
      className={styles.grantProgramNominationsContainer}
      data-testid="grant-program-applications-container"
    >
      <GrantProgramApplicationsTable
        data={mockGrantProgramCandidates}
        isLoading={mockIsLoading}
        totalResults={mockGrantProgramCandidates.length}
        refresh={mockRefresh}
        nextPage={null}
        previousPage={null}
      />
    </Flex>
  )
}
