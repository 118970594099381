import { GrantProgramCandidate } from '@percent/partner-dashboard/services/grants/grantsService.types'

export const mockGrantProgramCandidates: GrantProgramCandidate[] = [
  {
    candidateId: '101',
    currentState: 'Nominated',
    submittedAt: '2025-01-15T14:30:00Z',
    organisation: {
      name: 'Global Outreach Initiative',
      countryCode: 'USA'
    },
    nominatorEmail: 'nominator1@example.com'
  },
  {
    candidateId: '102',
    currentState: 'Nominated',
    submittedAt: '2025-01-20T09:00:00Z',
    organisation: {
      name: 'Green Future Alliance',
      countryCode: 'CAN'
    },
    nominatorEmail: 'nominator2@example.com'
  },
  {
    candidateId: '103',
    currentState: 'Nominated',
    submittedAt: '2025-01-22T16:45:00Z',
    organisation: {
      name: 'Helping Hands Association',
      countryCode: 'GBR'
    },
    nominatorEmail: 'nominator3@example.com'
  }
]
